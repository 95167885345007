const awsmobile = {

    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:07bd96c9-7c9c-4cf6-8ae6-5b08053214bb",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_O7WYxbaTr",
    "aws_user_pools_web_client_id": "2vj758oabd86lsfl3v4pcrud4j",
    "oauth": {
        "domain": "https://scio.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        // "redirectSignIn": "http://localhost:3011/users/redirect",
        // "redirectSignOut": "your-signout-uri",
        // "responseType": "code"
    }
    // "aws_cognito_client_secret" : "1s8sie9kjmqecr150qg156gnhsagg1e9uijq487o22m4abahi280",
    // "aws_user_pools_client_secret" : "fEem2XSg1ykCdPGHhsKtlx316csoYSIDzYIuq7/7WcM=",
    // "aws_user_pools_web_client_secret" : "fEem2XSg1ykCdPGHhsKtlx316csoYSIDzYIuq7",
    //"aws_project_region": "ap-northeast-1",
    // "aws_cognito_region": "ap-northeast-1",
    // "aws_user_pools_id": "ap-northeast-1_O7WYxbaTr",
    // "aws_user_pools_web_client_id": "7ohqndqn009ji68f4k698g0crn",
    // "aws_cognito_client_secret" : "1s8sie9kjmqecr150qg156gnhsagg1e9uijq487o22m4abahi280"
};
export default awsmobile;