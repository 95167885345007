import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Select from 'react-select';

import moment from "moment-timezone"

import { useAuth } from '../../../../app/modules/auth'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../common/style.css";
const getUserList = `/users/getUsersList`
const getPracticeList = `/api/practice/getPracticeListById`
const getColumnsByUser = `/api/Claims/getColumnsByUser`
const getPmList = `/api/pmsystem/getPmList`


 
const Touched: React.FC = () => {
   
    const [SelectedPmSystem, setSelectedPmSystem] = useState({})
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
       const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
       const [open, setOpen] = React.useState(false);

   
   

    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }
   
   
    const GetUsersList = async () => {
        const Result = await axios.get(getUserList)
        // setUserNameDatas(Result.data.data)
    }
  

    useEffect(() => {
        
        GetUsersList()
        GetPmList()
    }, [])

   
  
    const getPractice = (event: any) => {

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)
           

        } else {
            setSelectedPractice([])
           
        }

    }
  
   
    const getPMSystem = (event: any) => {
        //setSelectedPractice([])
        setSelectedPmSystem(event)
        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    };
   
    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))
    // var UsersListData = [{ label: "Select", value: "" }]
    // UserNameDatas.map((userList, index) => {
    //     UsersListData.push({
    //         label: userList.FirstName,
    //         value: userList._id
    //     })
    // })

    // var statuslistdata = [{ label: "", value: "" }]
    // getstatus.map((name) => (
    //     statuslistdata.push({
    //         label: name.label,
    //         value: name.value
    //     })
    // )
    // )
    // var rootlistdata = [{ label: "", value: "" }]
    // getrootcause.map((name) => (
    //     rootlistdata.push({
    //         label: name.label,
    //         value: name.value
    //     })
    // ))

    const DownloadFile = async (e:any)=>{
        e.preventDefault();
        setOpen(true)
        await axios.get(e.target.href,{responseType: 'arraybuffer',}).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Claims-${moment().format("YYYY-MM-DD")}.xlsx`);
            setOpen(false);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        return false;
    }


    return (
        <div >

<div className="header">
      <span className="section">Claim Management</span>
      <span className="arrow">&rsaquo;</span>
      <span className='section'>Touched/UnTouched Claims</span>
    </div>
    <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    {/* <h3 className='fw-bolder m-0'>View Claims</h3> */}
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>PM System</span>
                    </label><br />
                    <Select maxMenuHeight={180} value={SelectedPmSystem} onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />

                </div>
                <div className='col-sm-4 fv-row'>
                    <label className='col-lg-6 col-form-label fw-bold fs-6'>
                        <span className='required'>Practice Name</span>
                    </label>

                    <Select maxMenuHeight={180} value={SelectedPractice} isMulti onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />

                </div>
                <div className='col-1 pt-13'>
            <a 
            onClick={(e) => { DownloadFile(e) }} 
            
            href={`/api/Claims/DownloadTouchedDashboard?SelectedPractice=${JSON.stringify(SelectedPractice)}`} className='btn btn-primary'>Download</a>
          </div>

               </div>  
        </div>


    )

}
export { Touched }