
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { useAuth } from '../../../app/modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";



const getManagersList = `/users/getManagersList`
const getUserListById = `/users/getUserListById`
const UserDashbaord = `/users/UserDashbaord`
const AnalystDashbaord = `/users/AnalystDashbaord`
const getAnalystManagersList = `/users/getAnalystManagersList`
const getAnalystListById = `/users/getAnalystListById`
const getAnalystListByPractice = `/users/getAnalystListByPractice`


const AnalystDashboardWrapper: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ FirstName: "", _id: "" }])
  const [SelectedManager, setSelectedManager] = useState({ value: "", label: "" })
  const [SelectedUserList, setSelectedUserList] = useState([])
  const [UsersList, setUsersList] = useState([])
  const [PieChartData, setPieChartData] = useState<any>([])
  const [UserList, setUserList] = useState<any>([])
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    FromDate: '',
    ToDate: '',
  });
  const [labelName, setlabelName] = React.useState('Practice List');
  
  const GetUsersList = async () => {
    if (currentUser.RoleType === "AR-Caller") {
      setOpen(true)
      axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
        // sessionStorage.setItem("AnalystCount",JSON.stringify(_res.data.UserList));
      })
    }
    else if (currentUser.RoleType === "AR-Analyst") {
      setOpen(true)
      axios.get(`${AnalystDashbaord}?ManagerID=${currentUser.ReportingManager}&UserId=${JSON.stringify([{ value: currentUser._id }])}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
        sessionStorage.setItem("AnalystPieCount",JSON.stringify(_res.data.data));
        sessionStorage.setItem("AnalystCount",JSON.stringify(_res.data.UserList));
      })
    }
    else {
      if (SelectedManager.value) {
        setOpen(true)
        axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
          setOpen(false)
          setPieChartData(_res.data.data)
          setUserList(_res.data.UserList)
          sessionStorage.setItem("AnalystPieCount",JSON.stringify(_res.data.data));
          sessionStorage.setItem("AnalystCount",JSON.stringify(_res.data.UserList));
        })
      }
    }
  }


  //Analsyst List
  const getSelectedUsers = (event: any) => {

    let AllSelect : String = "";
    event.map((GetAll:any,inde:any)=>{
      if(GetAll.value == "All"){
        AllSelect = "Yes"
      }
    })
    if(AllSelect == "Yes"){
      event  = UsersList.filter((x:any)=>x.value !== "All")
    }
    setSelectedUserList(event)
    sessionStorage.setItem("AnalystList", JSON.stringify(event))
    if (Object.keys(event).length === 0) {
      setUserList([])
      setPieChartData([])
      return false;
    }

    if (UserList?.length === 0 && event.length > 1) {
      return false;
    }

    if (Object.keys(event).length < UserList?.length) {
      const res: any = UserList?.filter((item: any) => {
        return event.some((val: any) => val.value === item._id)
      })

      const removeData: any = UserList?.filter((item: any) => {
        return !event.some((val: any) => val.value === item._id)
      })

      if (PieChartData.length > 0 && removeData.length > 0) {
      removeData?.map((value:any)=>{
        PieChartData[1][1]-=value.ViewAllClaims;
        PieChartData[2][1]-=value.PCA;
        PieChartData[3][1]-=value.AutoClose;
      })
      PieChartData[1][1]=Math.abs(PieChartData[1][1]);
      PieChartData[2][1]=Math.abs(PieChartData[2][1]);
      PieChartData[3][1]=Math.abs(PieChartData[3][1]);
      }
      setPieChartData(PieChartData)
      setUserList(res);
      return false;
    }
     }


  //To know analyst under manager
  const getTeamMembers = async (event: any) => {
    setPieChartData([])
    setUserList([])
    setSelectedUserList([])
    setFormData({ FromDate: "", ToDate: "" })
    setSelectedManager(event)
    sessionStorage.setItem("ManagerAn", JSON.stringify(event))
    var PostData = {
      ReportingManagerId: event.value
    }
    await axios.post(getAnalystListById, PostData).then(_res => {
      var UsersList: any = [{value:"All",label:"All"}]
      _res.data.data.map((res: any, ind: any) => {
        UsersList.push({
          value: res._id,
          label: res.FirstName + " " + res.LastName,
        })
      })
      if(Object.keys(_res.data.data).length > 0){
        setUsersList(UsersList)
      }else {
        setUsersList([])
      }
      

    })

    sessionStorage.removeItem("AnalystList")
    sessionStorage.removeItem("FromDateAn")
    sessionStorage.removeItem("ToDateAn")
    sessionStorage.removeItem("ProdAnalystId")

  };

  const handleInputChange = async (event: any) => {
    const { name, value } = event.target;

    if (name === "FromDate") {
      sessionStorage.setItem("FromDateAn", JSON.stringify(value))
    }
    if (name === "ToDate") {
      sessionStorage.setItem("ToDateAn", JSON.stringify(value))
    }
    setFormData((prevState) => {
      return { ...prevState, [name]: value, }
    });
  }



  const Filter = async (ids: any) => {


    if (currentUser.RoleType !== "AR-Analyst") {
      if (!SelectedManager.value) {
        swal({
          title: '',
          icon: "info",
          text: `Please Select Manager`,
        }).then((_res: any) => {

        })
        return false;
      }
    }
    if (currentUser.RoleType !== "AR-Caller" && currentUser.RoleType !== "AR-Analyst") {
      if (SelectedUserList.length == 0) {
        swal({
          title: '',
          icon: "info",
          text: `Please Select Analyst  List`,
        }).then((_res: any) => {

        })
        return false;
      }
    }

      GetUsersList()
  }



  const ClearFilter = async (ids: any) => {


    await setFormData((prevState) => {
      return { ...prevState, FromDate: "", ToDate: "" }
    });
    //  setOpen(true)
    //   axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}`).then(_res => {
    //     setOpen(false)
    //     setPieChartData(_res.data.data)
    //     setUserList(_res.data.UserList)
    //   })

    sessionStorage.removeItem("FromDateAn")
    sessionStorage.removeItem("ToDateAn")


    if (currentUser.RoleType === "AR-Caller") {
      setOpen(true)
      axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
      })
    }
    else if (currentUser.RoleType === "AR-Analyst") {
      setOpen(true)
      axios.get(`${AnalystDashbaord}?ManagerID=${currentUser.ReportingManager}&UserId=${JSON.stringify([{ value: currentUser._id }])}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
      })
    }
    else {
      if (SelectedManager.value) {
        setOpen(true)
        axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : ""}&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}`).then(_res => {
          setOpen(false)
          setPieChartData(_res.data.data)
          setUserList(_res.data.UserList)
        })
      }
    }

  }



  const OpenClickedPerson = (_event: any) => {

    navigate('/AnalystDashboard', {
      state: {
        UserId: _event._id,
        UserName: _event.UserName,
        fromDate: formData.FromDate,
        toDate: formData.ToDate,
       
      }
    })

  }
  const GetManagersListData = async () => {

    setPieChartData([])
    setUserList([])
    if (currentUser.RoleType == "Manager" || currentUser.RoleType == "Assistant-Manager" || currentUser.RoleType == "Analyst_Come_Team_Leader" || currentUser.RoleType == "Caller_Come_Team_Leader" || currentUser.RoleType == "Team-Leader")  {
      setlabelName("Practice List")
      await axios.get(getAnalystListByPractice).then(_res => {
        var UsersList: any = [{_id:"All",FirstName:"All"}]
        _res.data.data.map((res: any, ind: any) => {
          UsersList.push({
            _id: res.value,
            FirstName: res.label,
          })
        })
        setUserNameDatas(UsersList)
      })
     
    }else {
      setlabelName("Manager")
      const Result = await axios.get(getAnalystManagersList)
      setUserNameDatas(Result.data.data)
    }
  }

  var ManagerAn: any;
  var AnalystList: any;
  var FromDateAn: any;
  var ToDateAn: any;

  const FetchBackData = async () => {
    // setUsersList([])

    // if (sessionStorage.getItem("ManagerAn")) {
      const SavedDataManager: any = sessionStorage.getItem("ManagerAn")
      ManagerAn = JSON.parse(SavedDataManager)

      const SavedDataAnalyst: any = sessionStorage.getItem("AnalystList")
      AnalystList = JSON.parse(SavedDataAnalyst)

      const SavedDataFromDate: any = sessionStorage.getItem("FromDateAn")
      FromDateAn = JSON.parse(SavedDataFromDate)
      const SavedDataToDate: any = sessionStorage.getItem("ToDateAn")
      ToDateAn = JSON.parse(SavedDataToDate)

      const analystCountParse: any = sessionStorage.getItem("AnalystCount")
      var analystCount = JSON.parse(analystCountParse)

      const analystPieCountParse: any = sessionStorage.getItem("AnalystPieCount")
      var analystPieCount = JSON.parse(analystPieCountParse)

      setSelectedManager(ManagerAn ? ManagerAn : {value:"",label:""})
      setSelectedUserList(AnalystList ? AnalystList : [])

      if (FromDateAn && ToDateAn) {
        setFormData({ FromDate: FromDateAn, ToDate: ToDateAn })
      } else {
        setFormData({ FromDate: "", ToDate: "" })
      }

        if(analystCount?.length > 0 && analystPieCount?.length > 1){
        
        setUserList(analystCount)
        setPieChartData(analystPieCount)
         return 
      }
      // else{
      //   console.log("else")
      //   var PostData = {
      //     ReportingManagerId: ManagerAn.value
      //   }
        // await axios.post(getAnalystListById, PostData).then(_res => {
        //   var UsersList: any = []
        //   _res.data.data.map((res: any, ind: any) => {
        //     UsersList.push({
        //       value: res._id,
        //       label: res.FirstName + " " + res.LastName,
        //     })
        //   })
        //   setUsersList(UsersList)
        // })

        // if (AnalystList?.length >0) {
        //   setOpen(true)
        //   axios.get(`${AnalystDashbaord}?ManagerID=${Object.keys(ManagerAn).length > 0 ? ManagerAn.value : ""}&UserId=${JSON.stringify(AnalystList)}&FromDate=${FromDateAn ? FromDateAn : ""}&ToDate=${ToDateAn ? ToDateAn : ""}`).then(_res => {
    
        //     setOpen(false)
        //     setPieChartData(_res.data.data)
        //     setUserList(_res.data.UserList)
        //   })
        // }

      // }
   


    // }
   
   

  }

  useEffect(() => {
    GetManagersListData()
    // GetUsersList()
    FetchBackData()
  }, [])


  const DownloadFile = async (e: any) => {
    e.preventDefault();
    setOpen(true)
    await axios.get(e.target.href, { responseType: 'arraybuffer', }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Claims-${moment().format("YYYYMMDDhms")}.xlsx`);
      document.body.appendChild(link);
      setOpen(false)
      link.click();
    }).catch((error) => console.log(error));
    return false;
  }
  const GoToBack = (event: any) => {
    navigate('/auth/*')
  }


  var UsersListData = [{ label: "Select", value: "" }]
  UserNameDatas.map((userList, index) => {
    UsersListData.push({
      label: userList.FirstName,
      value: userList._id
    })
  })


  return (
    <>

      <div className="header">
        <span className="section">Dashboard</span>
        <span className="arrow">&rsaquo;</span>
        <span className='section'>Over all Analyst Dashboard</span>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          {/* <div className='card-title m-0'>
            <span className='text-muted text-hover-primary' onClick={GoToBack}>Dashboard</span>
            <span className='text-muted'>{`>>`}</span>
            <span className='text-muted text-hover-primary'>{currentUser.RoleType !== "AR-Caller" ? " Analyst Productivity Dashboard" : "Productivity Dashboard"} </span>
          </div> */}
        </div>
        <hr />
        <div className='row'>
          {(currentUser.RoleType !== "AR-Caller" && currentUser.RoleType !== "AR-Analyst") ?
            <>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>{labelName}</span>
                </label><br />
                <Select maxMenuHeight={180} value={SelectedManager} onChange={getTeamMembers} id="Manager" name={"Manager"} options={UsersListData} />

              </div>
              <div className='col-sm-3 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Analyst List</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedUserList} isMulti onChange={getSelectedUsers} id="Practice" name={"Practice"} options={UsersList} />

              </div>
              
              
              </> : null}

          <div className='col-2'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>From</span>
            </label>
            <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
          </div>
          <div className='col-2'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>To</span>
            </label>
            <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="ToDate" className='form-control' />
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          <div className='col-1 pt-13'>
            {/* <a onClick={(e) => { DownloadFile(e) }}
              href={`/users/DownloadAnalystDashboard?ManagerID=${Object.keys(SelectedManager)?.length > 0 ? SelectedManager.value : currentUser?.ReportingManager}&UserId=${SelectedUserList?.length > 0 ? JSON.stringify(SelectedUserList) : JSON.stringify([{ value: currentUser?._id }])}&FromDate=${formData?.FromDate}&ToDate=${formData?.ToDate}`} className='btn btn-primary'>Download</a>  */}
                 <a onClick={(e) => { DownloadFile(e) }}
              href={`/users/DownloadAnalystDashboard?ManagerID=${Object.keys(SelectedManager)?.length > 0 ? SelectedManager.value : ""}&UserId=${SelectedUserList?.length > 0 ? JSON.stringify(SelectedUserList) : ""}&FromDate=${formData?.FromDate}&ToDate=${formData?.ToDate}`} className='btn btn-primary'>Download</a> 
          </div>

        </div><hr />

        <div className='row'>
          
            {PieChartData.length > 1 ? 
            <div className='col-6'>
            <Chart chartType="PieChart" data={PieChartData} options={{ title: "Overall Performance", is3D: true, }} width={"100%"} height={"200px"} />  </div>: null}
         
          <div className='col-6'>
            {UserList?.length > 0 ?
              <div className={`card card card-xl-stretch mb-xl-8`}>
                <div className='card-header border-0'>
                  <h3 className='card-title fw-bold text-dark'>{currentUser.RoleType === "AR-Caller" ? "" : "Analyst Performance"} </h3>
                </div>
             
                <div className='card-body pt-2'>

                  {UserList?.map((getDat: any, ind: any) => {

                    return <><div className='d-flex align-items-center mb-7'>
                      <div className='symbol symbol-50px me-5'>
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='' alt='' />
                      </div>
                     
                      <div className='flex-grow-1'>
                        <a href="#" onClick={() => OpenClickedPerson(getDat)} className='text-dark fw-bold text-hover-primary fs-6'>
                          {getDat.UserName}
                        </a>
                        <span className='text-muted d-block fw-semibold'>View All Claims : {getDat.ViewAllClaims ? getDat.ViewAllClaims : 0}</span>
                        <span className='text-muted d-block fw-semibold'>PCA : {getDat.PCA ? getDat.PCA : 0}</span>
                        <span className='text-muted d-block fw-semibold'>Auto Close : {getDat.AutoClose ? getDat.AutoClose : 0}</span>
                       
                      </div>
                    </div></>

                  })}
                </div>
              </div> : null}
          </div>
        </div>






      </div>

    </>
  )
}

export { AnalystDashboardWrapper }


