import React from 'react'

const thead: React.CSSProperties = {
    color: "#fff",
    backgroundColor: "#002d72",
    top: 70,
    position: 'sticky',
};

const Back: React.CSSProperties = {
    background: `linear-gradient(90deg, rgba(232,217,207,1) 17%, rgba(170,208,194,1) 100%, rgba(193,100,82,1) 100%)`,

};

type AgingTotalType = {
    data: any,
    total: {
        totalAmount: number
        totalCount: number

    },
    title: string
}
const options = { style: 'currency', currency: 'USD' };
const AgingTable = ({ data, total, title }: AgingTotalType) => {
    return (
        <>
            <div className='col-6 d-flex flex-column justify-content-center' style={Back}>
                <h4 className='text-center mb-8 captilize'>{title}</h4>
                <table
                    id='kt_table_users'
                    className='table-bordered align-middle table-row-dashed fs-6  dataTable no-footer font-family-base'
                >
                    <thead style={thead}>
                        <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
                            <th className='text-center px-3 p-2' style={thead}>Aging Wise</th>
                            <th className='text-center px-3 p-2' style={thead}>No of Claims</th>
                            <th className='text-center px-3 p-2' style={thead}>Claim Balance Total</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>

                        <tr>
                            <td className='text-center px-3 p-2'>0-30</td>
                            <td className='text-center px-3 p-2'>{data[0].Count0_30 || 0}</td>
                            <td className='text-center px-3 p-2'>{data[0].TotalAmount0_30.toLocaleString("en-US",options) || "$0"}</td>
                        </tr>

                        <tr>
                            <td className='text-center px-3 p-2'>30-60</td>
                            <td className='text-center px-3 p-2'>{data[0].Count30_60 || 0}</td>
                            <td className='text-center px-3 p-2'>{data[0].TotalAmount30_60.toLocaleString("en-US",options) || "$0"}</td>
                        </tr>

                        <tr>
                            <td className='text-center px-3 p-2'>60-90</td>
                            <td className='text-center px-3 p-2'>{data[0].Count60_90 || 0}</td>
                            <td className='text-center px-3 p-2'>{data[0].TotalAmount60_90.toLocaleString("en-US",options) || "$0"}</td>
                        </tr>

                        <tr>
                            <td className='text-center px-3 p-2'>90-120</td>
                            <td className='text-center px-3 p-2'>{data[0].Count90_120 || 0}</td>
                            <td className='text-center px-3 p-2'>{data[0].TotalAmount90_120.toLocaleString("en-US",options) || "$0"}</td>
                        </tr>

                        
                        <tr>
                            <td className='text-center px-3 p-2'>120 Above</td>
                            <td className='text-center px-3 p-2'>{data[0].Count120Above || 0}</td>
                            <td className='text-center px-3 p-2'>{data[0].TotalAmount120Above.toLocaleString("en-US",options) || "$0"}</td>
                        </tr>

                    </tbody>
                    <thead>
                                    <tr>
                                        <th className='px-3 py-2 text-center'>Grand Total</th>
                                        <th className='px-3 py-2 text-center'>{ total.totalCount || 0 }</th> 
                                        <th className='px-3 py-2 text-center'>{total.totalAmount.toLocaleString('en-US', options) || "$0" }</th> 
                                        
                                       
                                    </tr>
                                  </thead>
                </table>
            </div>
        </>
    );
}

export default AgingTable;

