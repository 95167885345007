const HideFooterButtons  = {
    columnsPanel: {
        sx: {
            "& .MuiDataGrid-panelFooter button:nth-child(2)": {
                display: "none"
            },
            "& .MuiDataGrid-panelFooter button:nth-child(1)": {
                display: "none"
            }
        }
    }
}
export {HideFooterButtons}