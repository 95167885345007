/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik'
import { getUserByToken, login } from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import swal from "sweetalert"
import axios from 'axios'
import { useAuth } from '../core/Auth'
import { Auth } from 'aws-amplify';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
  code: '',
  newpassword: '',
  confirmpassword: '',
  name: '',
  given_name: '',
  middle_name: '',
  preferred_username: '',
  locale: '',
  phone_number: '',
  updated_at: '',
}

const initialValuesReset = {

}

const forgotPasswordSchema = Yup.object().shape({

  // name: Yup.string().required('Name  is required'),
  // given_name: Yup.string().required('Given Name Password is required'),
  // middle_name: Yup.string().required('Middle is required'),
  // preferred_username: Yup.string().required('Preferred Username is required'),
  // locale: Yup.string().required('Locale'),
  // phone_number: Yup.string().required('Phone number'),
  // newpassword: Yup.string().required('New Password is required'),
  confirmpassword: Yup.string().required('Confirm Password is required'),
  // code: Yup.string().required('Confirmation Code is required'),
  newpassword: Yup.string().required('Email is required'),
})

export function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const [PasswordResetPage, setPasswordResetPage] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: !PasswordResetPage ? loginSchema : forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      if (!PasswordResetPage) {
        localStorage.removeItem("CurrentSession")
        await Auth.signIn(values.email, values.password).then(async (response) => {
          if (response.challengeName == "NEW_PASSWORD_REQUIRED") {

            setPasswordResetPage(true)
            // navigate("/auth/PasswordReset", {
            //   state: {
            //     Session: response
            //   }
            // });
          } else {
            var PostData = {
              id_token: response.signInUserSession.idToken.jwtToken
            }
            await axios.post(`/users/login`, PostData).then(async (Result) => {
              if (Result.data) {
                const { data: user } = await getUserByToken(Result.data._id)
                setLoading(false)
                setCurrentUser(user)
                saveAuth(user)
              } else {
                swal({
                  title: 'Credentials',
                  icon: "info",
                  text: `Incorrect UserName and Password`,
                }).then((_res: any) => {
        
                })
              }
            }).catch((err) => {
              setLoading(false)
              swal({
                title: 'Invalid',
                icon: "info",
                text: `Please wait some time...! :)`,
              }).then((_res: any) => {

              })
            })
          }


        }).catch((err) => {
          console.log("err", err)
          setLoading(false)
          swal({
            title: 'Invalid',
            icon: "info",
            text: `${err.message}`,
          }).then((_res: any) => {

          })
        });
      } else {
        try {

          await Auth.signIn(values.email, values.password).then(async (user) => {

            var RequiredParams = {
              // name: values.name,
              // given_name: values.given_name,
              // middle_name: values.middle_name,
              // preferred_username: values.preferred_username,
              // locale: values.locale,
              // phone_number: values.phone_number,
              session: user.Session
            }
            if(values.newpassword != values.confirmpassword){
              swal({
                title: 'Invalid',
                icon: "info",
                text: `Password doesn't match`,
              }).then((_res: any) => {

              })
              return false;
            }
            try {
              setLoading(true)
              await Auth.completeNewPassword(user, values.newpassword);
              setPasswordResetPage(false)
              setLoading(false)
              var EmptyValues : any = {email: "",password:""}
              formik.setValues(EmptyValues)
              swal({
                title: 'Success',
                icon: "info",
                text: `Password Reset Successfully...!`,
              }).then((_res: any) => {

              })
            } catch (error: any) {
              setLoading(false)
              console.log("error", error)
              swal({
                title: 'Invalid',
                icon: "info",
                text: `${error.message}`,
              }).then((_res: any) => {

              })
            }
          });
        } catch (error: any) {
          console.log("error", error)
          swal({
            title: 'Invalid',
            icon: "info",
            text: `${error.message}`,
          }).then((_res: any) => {

          })
        }

      }

    },
  })

  return (
    <div className={`${!PasswordResetPage ? "w-lg-500px p-10" : "w-lg-800px p-10"}`}>
      {
        !PasswordResetPage ?

          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
              <h1 className='fw-bolder mb-3'>
                <span style={{ color: "rgb(47,84,150)", fontSize: "28pt", lineHeight: "107%", fontFamily: 'BAUHS93' }}>Track</span>
                <span style={{ color: "rgb(83,129,53)", fontSize: "28pt", lineHeight: "107%", fontFamily: 'BAUHS93' }}>AR </span>
              </h1>
              <h6 className='fw-bolder mb-3'>Workflow Management System</h6>
              {/* <h1 className='text-dark fw-bolder mb-3'>Sign In</h1> */}

            </div>
            {/* begin::Heading */}


            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : null}

            {/* begin::Form group */}

            <div className='fv-row mb-8'>
              <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': formik.touched.email && formik.errors.email },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                type='email'
                name='email'
                autoComplete='off'
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              <input
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div />

              {/* begin::Link */}
              <Link to='/auth/forgot-password' className='link-primary'>
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Login</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}

          </form> :

          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='text-center mb-10'>
              {/* begin::Title */}
              <h1 className='text-dark fw-bolder mb-3'>Reset Password ?</h1>
              {/* end::Title */}

              {/* begin::Link */}
              {/* <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div> */}
              {/* end::Link */}
            </div>
            <div className='row'>
            
              <div className='col-6'>
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>New Password</label>
                  <input
                    type='password'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('newpassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.newpassword && formik.errors.newpassword },
                      {
                        'is-valid': formik.touched.newpassword && !formik.errors.newpassword,
                      }
                    )}
                  />
                  {formik.touched.newpassword && formik.errors.newpassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.newpassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-6'>
                <div className='fv-row mb-8'>
                  <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
                  <input
                    type='password'
                    placeholder=''
                    autoComplete='off'
                    {...formik.getFieldProps('confirmpassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      { 'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword },
                      {
                        'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
                      }
                    )}
                  />
                  {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.confirmpassword}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
                <span className='indicator-label'>Submit</span>
                {loading && (
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-light'
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </form>
      }

    </div>
  )
}
