import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import axios from "axios";
import { useAuth } from '../../../app/modules/auth'
import { Chart } from "react-google-charts";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
const getManagersList = `/users/getManagersList`
const getUserListById = `/users/getUserListById`
const UserDashbaord = `/users/UserDashbaord`
const getAnalystListByPractice = `/users/getAnalystListByPractice`


const DashboardWrapper: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails: any = location.state;
  const { currentUser }: any = useAuth()
  const [UserNameDatas, setUserNameDatas] = useState([{ FirstName: "", _id: "" }])
  const [SelectedManager, setSelectedManager] = useState({ value: "", label: "" })
  const [SelectedUserList, setSelectedUserList] = useState([])
  const [UsersList, setUsersList] = useState([])
  const [PieChartData, setPieChartData] = useState([])
  const [UserList, setUserList] = useState([])
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    FromDate: '',
    ToDate: '',
  });
  const [labelName, setlabelName] = React.useState('Practice List');
  const GetUsersList = async () => {
    if(currentUser.RoleType == "AR-Caller"){
      axios.get(`${UserDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
      })
    }else {
      if(SelectedManager.value){
        setOpen(true)
        axios.get(`${UserDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
          setOpen(false)
          setPieChartData(_res.data.data)
          setUserList(_res.data.UserList)
        })
      }
    }
    
  }
  const getSelectedUsers = (event: any) => {
    
    let AllSelect : String = "";
    event.map((GetAll:any,inde:any)=>{
      if(GetAll.value == "All"){
        AllSelect = "Yes"
      }
    })
    if(AllSelect == "Yes"){
      event  = UsersList.filter((x:any)=>x.value !== "All")
    }
    sessionStorage.setItem("CallerList",JSON.stringify(event))
    if(Object.keys(event).length >0){
      setSelectedUserList(event)
      // setOpen(true)
      // setSelectedUserList(event)
      // axios.get(`${UserDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${JSON.stringify(event)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`).then(_res => {
      //   setOpen(false)
      //   setPieChartData(_res.data.data)
      //   setUserList(_res.data.UserList)
      // })
    }
    else{
      setSelectedUserList([])
      setUserList([])
      setPieChartData([])
    }

  }
  const DownloadFile = async (e:any)=>{
    setOpen(true)
    e.preventDefault();
    await axios.get(e.target.href,{responseType: 'arraybuffer',}).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Claims-${moment().format("YYYYMMDDhms")}.xlsx`);
        document.body.appendChild(link);
        setOpen(false)
        link.click();
    }).catch((error) => console.log(error));
    return false;
}

  const getTeamMembers = async (event: any) => {
    setSelectedUserList([])
    setPieChartData([])
    setUserList([])
    setFormData({FromDate:"",ToDate:""})
 sessionStorage.setItem("ManagerCa",JSON.stringify(event))

    setSelectedManager(event)
    var PostData = {
      ReportingManagerId: event.value
    }
    await axios.post(getUserListById, PostData).then(_res => {
    
      var UsersList: any = [{value:"All",label:"All"}]
      _res.data.data.map((res: any, ind: any) => {
        UsersList.push({
          value: res._id,
          label: res.FirstName + " " + res.LastName,
        })
      })
      if(Object.keys(_res.data.data).length > 0){
        setUsersList(UsersList)  
      }else {
        setUsersList([])
      }
       
    })
    sessionStorage.removeItem("CallerList")
    sessionStorage.removeItem("FromDateCa")
    sessionStorage.removeItem("ToDateCa")
  
    
  };
  const handleInputChange = async (event: any) => {
    const { name, value } = event.target;
    if(name==="FromDate"){
      sessionStorage.setItem("FromDateCa",JSON.stringify(value))
     }
     if(name==="ToDate"){
      sessionStorage.setItem("ToDateCa",JSON.stringify(value))
     }
     setFormData((prevState) => {
      return { ...prevState, [name]: value, }
    });
  }
  const Filter = async (ids: any) => {
    if(currentUser.RoleType !== "AR-Caller" && currentUser.RoleType !== "AR-Analyst"){
      if(!SelectedManager.value){
        swal({
          title: '',
          icon: "info",
          text: `Please Select Manager`,
        }).then((_res: any) => {

        })
        return false;
      }
    }
    if(currentUser.RoleType !== "AR-Caller"){
      if(SelectedUserList.length == 0){
        swal({
          title: '',
          icon: "info",
          text: `Please Select Team List`,
        }).then((_res: any) => {

        })
        return false;
      }
    }
    // if (!formData.FromDate) {
    //   swal({
    //     title: 'Date',
    //     icon: "info",
    //     text: `Please choose From Date`,
    //   }).then((_res: any) => {

    //   })
    //   return false;
    // }
    // if (!formData.ToDate) {
    //   swal({
    //     title: 'Date',
    //     icon: "info",
    //     text: `Please choose To Date`,
    //   }).then((_res: any) => {

    //   })
    //   return false;
    // }
    GetUsersList()
  }



  const ClearFilter = async (ids: any) => {

    await setFormData((prevState) => {
      return { ...prevState, FromDate: "", ToDate: "" }
    },);
    // GetUsersList()
    if(currentUser.RoleType == "AR-Caller"){
      axios.get(`${UserDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}`).then(_res => {
        setOpen(false)
        setPieChartData(_res.data.data)
        setUserList(_res.data.UserList)
      })
    }else {
      if(SelectedManager.value){
        setOpen(true)
        axios.get(`${UserDashbaord}?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${SelectedUserList.length > 0 ? JSON.stringify(SelectedUserList) : ""}`).then(_res => {
          setOpen(false)
          setPieChartData(_res.data.data)
          setUserList(_res.data.UserList)
        })
      }
    }
  }
  const OpenClickedPerson = (_event: any) => {

    navigate('/PersonDashboard', {
      state: {
        UserId: _event.UserId,
        UserName:_event.UserName,
        fromDate:formData.FromDate,
        toDate:formData.ToDate
      }
    })

  }
  const GetManagersListData = async() =>{

    if (currentUser.RoleType == "Manager" || currentUser.RoleType == "Assistant-Manager" || currentUser.RoleType == "Analyst_Come_Team_Leader" || currentUser.RoleType == "Caller_Come_Team_Leader" || currentUser.RoleType == "Team-Leader")  {
      setlabelName("Practice List")
      await axios.get(getAnalystListByPractice).then(_res => {
        var UsersList: any = [{_id:"All",FirstName:"All"}]
        _res.data.data.map((res: any, ind: any) => {
          UsersList.push({
            _id: res.value,
            FirstName: res.label,
          })
        })
        setUserNameDatas(UsersList)
      })
    }else {
      setlabelName("Manager")
      const Result = await axios.get(getManagersList)
      setUserNameDatas(Result.data.data)
    }
  }
  useEffect(() => {
    GetUsersList()
  }, [])

  useEffect(() => {
    GetManagersListData()
    GetUsersList()
  }, [])

  var ManagerCa:any;
  var CallerList:any;
  var FromDateCa:any;
  var ToDateCa:any;


  const FetchBackData=async()=>{

    const SavedDataManager:any=sessionStorage.getItem("ManagerCa")
    ManagerCa=JSON.parse(SavedDataManager)
  
    const SavedDataAnalyst:any=sessionStorage.getItem("CallerList")
    CallerList=JSON.parse(SavedDataAnalyst)
  
    const SavedDataFromDate:any=sessionStorage.getItem("FromDateCa")
    FromDateCa=JSON.parse(SavedDataFromDate)

    const SavedDataToDate:any=sessionStorage.getItem("ToDateCa")
    ToDateCa=JSON.parse(SavedDataToDate)
  
    if(ManagerCa){
      setSelectedManager(ManagerCa)
      setSelectedUserList(CallerList ? CallerList : [])
     
    }
    if(FromDateCa && ToDateCa){
      setFormData({FromDate:FromDateCa,ToDate:ToDateCa})
    }else{
      setFormData({FromDate:"",ToDate:""})
    }
  
    if( Object.keys(ManagerCa)?.length >0 ){
  
    var PostData = {
      ReportingManagerId: ManagerCa.value
    }
    await axios.post(getUserListById, PostData).then(_res => {
     
      var UsersList: any = [{value:"All",label:"All"}]
      _res.data.data.map((res: any, ind: any) => {
        UsersList.push({
          value: res._id,
          label: res.FirstName + " " + res.LastName,
        })
      })
      setUsersList(UsersList)
    })
    } 

    if(Object.keys(CallerList).length >0){
      setOpen(true)
    axios.get(`${UserDashbaord}?ManagerID=${Object.keys(ManagerCa).length > 0 ? ManagerCa.value : "" }&UserId=${JSON.stringify(CallerList)}&FromDate=${FromDateCa || ""}&ToDate=${ToDateCa || ""}`).then(_res => {
      setOpen(false)
      setPieChartData(_res.data.data)
      setUserList(_res.data.UserList)
    })
    }
    else{
      setSelectedUserList([])
      setUserList([])
      setPieChartData([])
    }    
    }
   
  useEffect(()=>{
    FetchBackData()
  },[])

  var UsersListData = [{ label: "Select", value: "" }]
  UserNameDatas.map((userList, index) => {
    UsersListData.push({
      label: userList.FirstName,
      value: userList._id
    })
  })
  return (
    <>
    <div className="header">
      <span className="section">Dashboard</span>
      <span className="arrow">&rsaquo;</span>
      <span className='section'>Caller Dashboard</span>
    </div>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <div >
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{currentUser.RoleType !== "AR-Caller" ? "Caller Productivity Dashboard" : "Productivity Dashboard"} </h3>
          </div>
        </div>
        <hr />
        <div className='row'>
          {currentUser.RoleType !== "AR-Caller" ?
            <>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className='required'>{labelName}</span>
                </label><br />
                <Select maxMenuHeight={180} value={SelectedManager} onChange={getTeamMembers} id="Manager" name={"Manager"} options={UsersListData} />

              </div>
              <div className='col-sm-4 fv-row'>
                <label className='col-lg-6 col-form-label fw-bold fs-6'>
                  <span className='required'>Team List</span>
                </label>

                <Select maxMenuHeight={180} value={SelectedUserList} isMulti onChange={getSelectedUsers} id="Practice" name={"Practice"} options={UsersList} />

              </div></> : null}

          <div className='col-3'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>From Date</span>
            </label>
            <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
          </div>
          <div className='col-3'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>To Date</span>
            </label>
            <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="ToDate" className='form-control' />
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { Filter(e) }} className='btn btn-primary'  >Filter</button>
          </div>
          <div className='col-1 pt-13'>
            <button onClick={(e) => { ClearFilter(e) }} className='btn btn-primary'  >Clear</button>
          </div>
          <div className='col-1 pt-13'>
            <a onClick={(e) => { DownloadFile(e) }} href={`/users/DownloadDashboard?ManagerID=${Object.keys(SelectedManager).length > 0 ? SelectedManager.value : "" }&UserId=${JSON.stringify(SelectedUserList)}&FromDate=${formData.FromDate}&ToDate=${formData.ToDate}`} className='btn btn-primary'>Download</a>
          </div>

        </div><hr />

        <div className='row'>




          <div className='col-6'>
            {PieChartData.length > 0 ? <Chart chartType="PieChart" data={PieChartData} options={{ title: "Overall Performance", is3D: true, }} width={"100%"} height={"200px"} /> : null}
          </div>
          <div className='col-6'>
            {Object.keys(UserList).length > 0 ?
              <div className={`card card card-xl-stretch mb-xl-8`}>
                {/* begin::Header */}
                <div className='card-header border-0'>
                  <h3 className='card-title fw-bold text-dark'>{currentUser.RoleType === "AR-Caller" ? "" : "Caller Performance"} </h3>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body pt-2'>

                  {UserList.map((getDat: any, ind: any) => {

                    return <><div className='d-flex align-items-center mb-7'>
                      {/* begin::Avatar */}
                      <div className='symbol symbol-50px me-5'>
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} className='' alt='' />
                      </div>
                      {/* end::Avatar */}
                      {/* begin::Text */}
                      <div className='flex-grow-1'>
                        <a href="#" onClick={() => OpenClickedPerson(getDat)} className='text-dark fw-bold text-hover-primary fs-6'>
                          {getDat.UserName}
                        </a>
                        {/* <span className='text-muted d-block fw-semibold'>Assigned : {getDat.Total}</span> */}
                        <span className='text-muted d-block fw-semibold'>Worked : {getDat.Closed}</span>
                        <span className='text-muted d-block fw-semibold'>Auto Close : {getDat.AutoClose}</span>
                        <span className='text-muted d-block fw-semibold'>Pending : {getDat.Pending}</span>
                        
                      </div>
                      {/* end::Text */}
                    </div></>

                  })}
                </div>
                {/* end::Body */}
              </div> : null}
          </div>
        </div>






      </div>

    </>
  )
}

export { DashboardWrapper }
