import React, { useState, useEffect,useRef } from 'react'
import { FC } from 'react'
import { DataGridPro, GridColDef, GridFilterModel, DataGridProProps, GridSortModel, GridRowParams, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridValueGetterParams, GridRowModel, getGridSingleSelectOperators, GridComparatorFn, GridFilterItem, GridCellParams } from '@mui/x-data-grid-pro';
import Button, { ButtonProps } from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import moment from "moment";
import { TableStyles } from "../../wizards/common/TableStyle"
import { HideFooterButtons } from "../../wizards/common/HideFooterButtons"
import { buttonBaseProps } from '../../wizards/common/buttonBaseProp';
import "../../wizards/common/style.css";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
const UpdateColumnsByUser = `/api/Claims/UpdateColumnsByUser`
const DownlaodExcelAnalystWiseProduction = `/api/AnalystProductivity/DownlaodExcelAnalystWiseProduction`

export interface ChildProps {
    passChildData?: any;
    SelectedPmSystem?: Object;
    SelectedPractice?: Object;
    SelectedManager?: Object;
    FromDate : string,
    ToDate : string,
    SelectMonth : string,
    isSubmit : boolean
  }
  
type Props = {
    passChildData?: any;
    UserData?: {
        SelectedPmSystem? : Object,
        SelectedPractice? : Object,
        FromDate : string,
        ToDate : string,
        SelectedManager?: Object,
    }
}

const ArAnalystProduction: FC<Props> = (passChildData:any) => {

    
    const [DefaultColumns, setDefaultColumns] = useState({ Bill: false, DateOfService: false, PatientName: false, PayerName: false, ClaimStatus: false, ClaimBilledAmount: false, ClaimPayment: false, ClaimBalance: false, Account: false, ProviderName: false, SystemStatus: false, Aging: false, DueDate: false, Comments: false, updatedAt: false, FirstName: false, Location: false, MemberId: false, PayerMix: false, IntialClaimDate: false, ClaimAdjustemnt: false, Stage: false, RoleResponsibilityCategory: false, DenialReason: false, ServiceType: false, RootCause: false, Modifier: false, PCAStatus: false, ProcedureCode: false, FacilityName: false, PayerResponsibility: false, Indication: false, ProcedureBalance: false, FiledStatus: false, PatientDOB: false, AdjustmentReason: false, CompanyName: false, OrginalICN: false, Diagnosis: false, SecondaryInsuranceCompany: false, DOE: false, Unit: false, ProcedureBilledAmount: false, ProcedurePayment: false, ProcedureAdjustment: false,Status:false })
    const prevProps = useRef<any>();
    const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
        items: [],
    });
    const [sortModel, setSortModel] = React.useState<GridSortModel>([
        {
            field: 'updatedAt',
            sort: 'desc',
        },
    ]);
    const [isLoading, setLoading] = useState(false)
    const [pageState, setPageState] = useState({
        isLoading: false,
        data: [],
        total: 0,
        totalClaimBalance : 0.00,
        pageSize: {
            page: 0,
            pageSize: 100
        }
        // page: 1,
        // pageSize: 20
    })
    useEffect(() => {
        const fetchData = async () => {
          try {
           
            if(Object.keys(passChildData.UserData).length > 0){
                
                if (prevProps.current !== passChildData) {
                    
                    setLoading(true)
                    var Result = await axios.get(`${DownlaodExcelAnalystWiseProduction}?UsersList=${JSON.stringify(passChildData.UserData?.SelectedManager)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=view&sort=${JSON.stringify(sortModel)}&StartDate=${passChildData.UserData?.FromDate}&ToDate=${passChildData.UserData?.ToDate}`)
                    setPageState(old => ({ ...old, isLoading: false, data: Result.data.data, total: Result.data.total }))
                    setLoading(false)
                }
            }
            prevProps.current =  passChildData;
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }, [passChildData,pageState.pageSize.page, pageState.pageSize.pageSize, filterModel.items, sortModel]);
    
    const handleExport = async (_e: any,downlaodType: string) => {
        setLoading(true)
        axios.get(`${DownlaodExcelAnalystWiseProduction}?UsersList=${JSON.stringify(passChildData.UserData?.SelectedManager)}&page=${pageState.pageSize.page}&limit=${pageState.pageSize.pageSize}&filter=${JSON.stringify(filterModel.items)}&type=downlaod&sort=${JSON.stringify(sortModel)}&StartDate=${passChildData.UserData?.FromDate}&ToDate=${passChildData.UserData?.ToDate}&downlaodType=${downlaodType}`,{responseType: 'arraybuffer',}).then((response) => {
            setLoading(false)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Consolidated Production-${moment().format("YYYY-MM-DD")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        }).catch((error) => console.log(error));
        
    }
    const CustomToolBar = () => {

        return (<GridToolbarContainer>
            <GridToolbarDensitySelector />
            <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e,"details")}
            >Details Report</Button>
             <Button
                {...buttonBaseProps}
                onClick={(e) => handleExport(e,"summary")}
            >Summary Report</Button>
        </GridToolbarContainer>
        );
    }
    const getDetailPanelHeight = React.useCallback(() => 400, []);

    const columns: GridColDef[] = [
        { align: 'center', headerAlign: "center", field: 'UserName', headerName: 'User Name', width: 150, },
        { align: 'center', headerAlign: "center", field: 'reporting_manager_name', headerName: 'Reporting Manager Name', width: 100, },
        { align: 'center', headerAlign: "center", field: 'TotalCalled', headerName: 'Analysis Done', width: 150, },
        { align: 'center', headerAlign: "center", field: 'FinalPCACount', headerName: 'PCA', width: 150, },
        { align: 'center', headerAlign: "center", field: 'TotalWorked', headerName: 'Total Worked', width: 150, },
        { align: 'center', headerAlign: "center", field: 'TotalEfficiency', headerName: 'Efficiency', width: 150, },
    ]

    const setColumnVisibilityModel = (e: any) => {
        setDefaultColumns(e)
        axios.post(UpdateColumnsByUser, { Columns: JSON.stringify([e]), PageType: "ViewClaims" }).then((res) => {
        })
    }
    function DetailPanelContent({ row: rowProp }: { row: any }) {
        return (
            <DataGridPro
            
            density="compact"
            columns={[
                
                // { align: 'center', headerAlign: "center", field: 'UserName', headerName: 'User Name', width: 150, editable: true,},
                // { align: 'center', headerAlign: "center", field: 'roles', headerName: 'Role', width: 100, },
                // { align: 'center', headerAlign: "center", field: 'reporting_manager_name', headerName: 'Reporting Manager Name', width: 100, },
                { align: 'center', headerAlign: "center", field: 'practice_names', headerName: 'Practice Name', width: 150, },
                { align: 'center', headerAlign: "center", field: 'Total', headerName: 'Total', width: 100, },
                { align: 'center', headerAlign: "center", field: 'PCA', headerName: 'PCA', width: 100, },
                
                { align: 'center', headerAlign: "center", field: 'Target', headerName: 'Target', width: 100, },
            ]}
            rows={rowProp.SubData}
            sx={{ flex: 1 }}
            hideFooter
          />
        );
      }
    const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(({ row }) => <DetailPanelContent row={row} />, []);
    return (
        <div >
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ width: "100%", }} className="pt-5">
                <DataGridPro
                    rowThreshold={0}
                    sx={TableStyles}
                    rowHeight={40}
                    componentsProps={HideFooterButtons}
                    autoHeight={true}
                    rows={pageState.data}
                    rowCount={pageState.total}
                    loading={pageState.isLoading}
                    getDetailPanelHeight={getDetailPanelHeight}
                    pageSizeOptions={[20, 40, 60, 80, 100]}
                    getDetailPanelContent={getDetailPanelContent}
                    paginationModel={{
                        page: pageState.pageSize.page == 0 ? pageState.pageSize.page : pageState.pageSize.page,
                        pageSize: pageState.pageSize.pageSize
                    }}
                    paginationMode="client"
                    onPaginationModelChange={(newPageSize: any) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
                    pagination
                    columns={columns}
                    density="comfortable"
                    filterModel={filterModel}
                    onColumnVisibilityModelChange={(newModel: any) => setColumnVisibilityModel(newModel)}
                    onSortModelChange={(model: any) => setSortModel(model)}
                    columnVisibilityModel={DefaultColumns}
                    
                    components={{ Toolbar: CustomToolBar }}

                    sortModel={sortModel}
                    onFilterModelChange={(newFilterModel: React.SetStateAction<GridFilterModel>) => setFilterModel(newFilterModel)}
                />
            </div>
        </div>
    )

}
export default  ArAnalystProduction;