import React, { useState, useEffect ,HTMLAttributes} from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import axios from "axios";
import { useAuth } from '../../../app/modules/auth'
import { Chart, ReactGoogleChartProps } from "react-google-charts";
import Select from 'react-select';
import swal from "sweetalert"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import '../../../_metronic/assets/css/global.css'
import TableRows from '../../components/TableRows';
import AgingTable from '../../components/AgingTable';



const API_URL = process.env.REACT_APP_THEME_API_URL
const getAnalystProductivityDashboard = `/users/getAnalystProductivityDashboard`
const getPmList = `/api/pmsystem/getPmList`
const getPracticeList = `/api/practice/getPracticeListById`
const options = { style: 'currency', currency: 'USD' };


type GrandTotalType = {
    totalAmount0:number
    totalCount0:number
    totalAmount60:number
    totalCount60:number
    totalAmount120:number
    totalCount120:number
}

type AgingTotalType={
    totalAmount:number
    totalCount:number
}


const AnalystProductivity: React.FC = () => {

const navigate = useNavigate();
const location = useLocation();
const userDetails: any = location.state;
const { currentUser }: any = useAuth()
const [UserNameDatas, setUserNameDatas] = useState([{ FirstName: "", _id: "" }])
const [PieChartData, setPieChartData] = useState([])
const [DayWiseUserData, setDayWiseUserData] = useState<any>([])

const [ChartData, setChartData] = useState<any>([])
const [ChartDataForClaim, setChartDataForClaim] = useState<any>([])
const [PayerNameTableData, setPayerNameTableData] = useState<any>([])

//Provider wise
const [ChartProviderData, setChartProviderData] = useState<any>([])
const [ChartProviderDataForClaim, setChartProviderDataForClaim] = useState<any>([])
const [ProviderTableData, setProviderTableData] = useState<any>([])

//Rootcause wise
const [RootCauseDataWiseData, setRootCauseDataWiseData] = useState<any>([])
const [RootCauseDataWiseDataForClaim, setRootCauseDataWiseDataForClaim] = useState<any>([])
const [RootCauseTableData, setRootCauseTableData] = useState<any>([])

//Status wise
const [ChartStatusWiseData, setChartStatusWiseData] = useState<any>([])
const [ChartStatusWiseDataForClaim, setChartStatusWiseDataForClaim] = useState<any>([])
const [StatusTableData, setStatusTableData] = useState<any>([])

//Practice wise
const [ChartPracticeWiseData, setChartPracticeWiseData] = useState<any>([])
const [ChartPracticeWiseDataForClaim, setChartPracticeWiseDataForClaim] = useState<any>([])
const [PracticeTableData, setPracticeTableData] = useState<any>([])

//Aging Wise
const [ChartAgingWiseData, setChartAgingWiseData] = useState<any>([])
const [ChartAgingWiseDataForClaim, setChartAgingWiseDataForClaim] = useState<any>([])
const [AgingeTableData, setAgingeTableData] = useState<any>([])


const [AnalystName,setAnalystName]=useState<String>()
const [loading, setLoading] = useState(false)
const [showing, setShowing] = useState(false)
const [selectedRadio, setSelectedRadio] = useState<string>("")

const [formData, setFormData] = useState({
FromDate: '',
ToDate: '',
});
const [SelectedPractice, setSelectedPractice] = useState<any>([])
const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
const [myLoading, setMyLoading] = useState(false)
const [AnalystId,setAnalystId]=useState<any>()
const [FromDate,setFromDate]=useState<any>()
const [ToDate,setToDate]=useState<any>()
const [Disabled,setDisabled]=useState<boolean>(false)
const [GrandTotal,setGrandTotal]=useState<GrandTotalType>({
     totalAmount0:0,
     totalCount0:0,
     totalAmount60:0,
     totalCount60:0,
     totalAmount120:0,
     totalCount120:0
})

const [AgingTotal,setAgingTotal]=useState<AgingTotalType>({
    totalAmount:0,
    totalCount:0,
   
})


//Payer wise chart options
const ChartOptionsForClaim={
    title: "Payer Wise ~ No of claims", fontSize: 8, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    
    }
    
    const ChartOptions={
    title: "Payer Wise ~ Claim Balance", fontSize: 8, isStacked: true, hAxis: { title: "Payer Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: 'black' } },  colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }
    
    //status wise chart options
    const ChartOptionsStatusForClaim={
    title: "Status Wise ~ No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    }
    
    const ChartOptionsStatus={
        title: "Status Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Status Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },
        colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
        }
    //Provider wise chart options
    const ChartOptionsProviderForClaim={
    title: "Provider Wise ~ No of claims", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    }
    
    const ChartOptionsProvider={
    title: "Provider Wise ~ Claim Balance", fontSize: 10, isStacked: true, hAxis: { title: "Provider Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },
    colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }
    
    //Rootcause wise chart options
    const ChartOptionsRootCauseForClaim={
    title: "RootCause Wise ~ No of claims", fontSize: 12, isStacked: true, hAxis: { title: "RootCause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    }
    const ChartOptionsRootCause={
    title: "RootCause Wise ~ Claim Balance", fontSize: 12, isStacked: true, hAxis: { title: "RootCause Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },
    colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }
    
    //Practice wise chart options
    const ChartOptionsPracticeForClaim={
    title: "Practice Wise ~ No of claims", fontSize: 12, isStacked: true, hAxis: { title: "Practice Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    }
    
    
    const ChartOptionsPractice={
    title: "Practice Wise ~ Claim Balance", fontSize: 12, isStacked: true, hAxis: { title: "Practice Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },
    colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }
    
    
    //Aging wise chart options
    const ChartOptionsAgingForClaim={
    title: "Aging Wise ~ No of claims", fontSize: 12, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "No of Claims", titleTextStyle: { color: "black" } },
    backgroundColor: '#e8d9cf',colors: ['#c16452', 'transparent'],
    }
    
    const ChartOptionsAging={
    title: "Aging Wise ~ Claim Balance", fontSize: 12, isStacked: true, hAxis: { title: "Aging Wise", minValue: 0, titleTextStyle: { color: 'black' } }, vAxis: { title: "Claim Balance", titleTextStyle: { color: "black" } },
    colors: ['#214878', 'transparent'], backgroundColor: "#aad0c2"
    }




useEffect(()=>{
    if(userDetails === null){
        const storedData = sessionStorage.getItem("ProdAnalystId");

        if (storedData !== null) {
          const parseObj: any = JSON.parse(storedData);
          setAnalystId(parseObj.UserId)
          setFromDate(parseObj.fromDate)
          setToDate(parseObj.toDate)
          setAnalystName(parseObj.UserName);
        } else {
        GoToBack();
        }
}else{
    const obj={
        UserId:userDetails.UserId,
        fromDate:userDetails.fromDate,
        toDate:userDetails.toDate,
        UserName:userDetails.UserName
    }
    sessionStorage.setItem("ProdAnalystId",JSON.stringify(obj))
    setAnalystId(userDetails.UserId)
    setFromDate(userDetails.fromDate)
    setToDate(userDetails.toDate)
    setAnalystName(userDetails.UserName);
}


},[])

async function fetchData(radioValue:string){
setSelectedRadio(radioValue)
setLoading(true)
setDisabled(false)

setMyLoading(true)
setPieChartData([])
setChartData([])
setChartDataForClaim([])
setPayerNameTableData([])
setChartStatusWiseData([])
setChartStatusWiseDataForClaim([])
setStatusTableData([])
setChartProviderData([])
setChartProviderDataForClaim([])
setProviderTableData([])
setRootCauseDataWiseData([])
setRootCauseDataWiseDataForClaim([])
setRootCauseTableData([])
setChartPracticeWiseData([])
setChartPracticeWiseDataForClaim([])
setPracticeTableData([])
setChartAgingWiseData([])
setChartAgingWiseDataForClaim([])
setAgingeTableData([])
setGrandTotal({
    totalAmount0:0,
    totalCount0:0,
    totalAmount60:0,
    totalCount60:0,
    totalAmount120:0,
    totalCount120:0
})
setAgingTotal({
    totalAmount:0,
    totalCount:0
  })

if ( AnalystId ) {
  axios.get(`${getAnalystProductivityDashboard}?FromDate=${FromDate}&ToDate=${ToDate}&radio=${radioValue}&AnalystId=${JSON.stringify(AnalystId)}`).then(_res => {


      if (!_res.data.Result) {

          swal({
              title: '',
              icon: "info",
              text: `${_res.data.Message}`,
          }).then((_res: any) => {

          })
      }
      
      if (_res.data.Radio === "Day Wise") {
        console.log("_res.data",_res.data)
          setDayWiseUserData(_res.data)
          setShowing(true)
          setLoading(false)
          setMyLoading(false)
      }
      if (_res.data.Radio === "OVER ALL PRODUCTIVITY") {
          console.log("Data", _res.data.data)
          setPieChartData(_res.data.data)
          setShowing(true)
          setLoading(false)
          setMyLoading(false)
      }
      if (_res.data.Radio === "PAYER WISE") {
          setChartData(_res.data.PayerNameData)
          setChartDataForClaim(_res.data.PayerNameDataForClaim)
          setPayerNameTableData(_res.data.PayerNameDataTable)
          setGrandTotal({
            totalAmount0:_res.data.totalAmount0,
            totalCount0:_res.data.totalCount0,
            totalAmount60:_res.data.totalAmount60,
            totalCount60:_res.data.totalCount60,
            totalAmount120:_res.data.totalAmount120,
            totalCount120:_res.data.totalCount120
          })
          setShowing(true)
          setLoading(false)
          setMyLoading(false)
      }
      if (_res.data.Radio === "STATUS WISE") {
          setChartStatusWiseData(_res.data.StatusWiseData)
          setChartStatusWiseDataForClaim(_res.data.StatusWiseDataForClaim)
          setStatusTableData(_res.data.StatusWiseTableData)
          setGrandTotal({
            totalAmount0:_res.data.totalAmount0,
            totalCount0:_res.data.totalCount0,
            totalAmount60:_res.data.totalAmount60,
            totalCount60:_res.data.totalCount60,
            totalAmount120:_res.data.totalAmount120,
            totalCount120:_res.data.totalCount120
          })
          setShowing(true)
          setLoading(false)
          setMyLoading(false)
      }
      if (_res.data.Radio === "PROVIDER WISE") {
          setChartProviderData(_res.data.ProviderNameWiseData)
          setChartProviderDataForClaim(_res.data.ProviderNameWiseDataForClaim)
          setProviderTableData(_res.data.ProviderWiseTableData)
          setGrandTotal({
            totalAmount0:_res.data.totalAmount0,
            totalCount0:_res.data.totalCount0,
            totalAmount60:_res.data.totalAmount60,
            totalCount60:_res.data.totalCount60,
            totalAmount120:_res.data.totalAmount120,
            totalCount120:_res.data.totalCount120
          })
          setShowing(true)
          setLoading(false)
          setMyLoading(false)
      }
      if (_res.data.Radio === "ROOTCAUSE WISE") {
          setRootCauseDataWiseData(_res.data.RootCauseData)
          setRootCauseDataWiseDataForClaim(_res.data.RootCauseDataForClaim)
          setRootCauseTableData(_res.data.RootCauseWiseTableData)
          setGrandTotal({
            totalAmount0:_res.data.totalAmount0,
            totalCount0:_res.data.totalCount0,
            totalAmount60:_res.data.totalAmount60,
            totalCount60:_res.data.totalCount60,
            totalAmount120:_res.data.totalAmount120,
            totalCount120:_res.data.totalCount120
          })
          setShowing(true)
          setLoading(false)
           setMyLoading(false)
      }
      if (_res.data.Radio === "PRACTICE WISE") {
          setChartPracticeWiseData(_res.data.PracticeWiseData)
          setChartPracticeWiseDataForClaim(_res.data.PracticeWiseDataForClaim)
          setPracticeTableData(_res.data.PracticeWiseTableData)
          setGrandTotal({
            totalAmount0:_res.data.totalAmount0,
            totalCount0:_res.data.totalCount0,
            totalAmount60:_res.data.totalAmount60,
            totalCount60:_res.data.totalCount60,
            totalAmount120:_res.data.totalAmount120,
            totalCount120:_res.data.totalCount120
          })
        
          setShowing(true)
          setLoading(false)
           setMyLoading(false)
      }
      if (_res.data.Radio === "AGING WISE") {
          setChartAgingWiseData(_res.data.AgeingWiseChartData)
          setChartAgingWiseDataForClaim(_res.data.AgeingWiseChartDataForClaim)
          setAgingeTableData(_res.data.AgeingWiseTableData)
          setAgingTotal({
            totalAmount:_res.data.totalAmount,
            totalCount:_res.data.totalCount
          })
          setShowing(true)
          setLoading(false)
          setMyLoading(false)

      }

  })
} else {
  setSelectedPractice([])
}
}

const handleRadioChange = (event: any) => {
    fetchData(event.target.value)
}


const Reload=()=>{
    fetchData(selectedRadio);
}

const handleInputChange = async (event: any) => {
const { name, value } = event.target;
await setFormData((prevState) => {
  return { ...prevState, [name]: value, }
});
}


const GoToBack = () => {
navigate('/AnalystProductivity')
}



var UsersListData = [{ label: "Select", value: "" }]
UserNameDatas.map((userList, index) => {
UsersListData.push({
  label: userList.FirstName,
  value: userList._id
})
})
var PmListData = [{ label: "Select", value: "" }]
getPmListData.map((name) => (
PmListData.push({
  label: name.PmName,
  value: name._id
})
))
console.log('DayWiseUserData',DayWiseUserData.length)
return (
<div >

<div className="header">
<span className="section">Dashboard</span>
<span className="arrow">&rsaquo;</span>
<span className='section'>Over all Analyst Dashboard</span>
<span className="arrow">&rsaquo;</span>
<span className='section'>Analyst Dashboard</span>
</div>

  <Backdrop
sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
open={myLoading}
// onClick={handleClose}
>
<CircularProgress color="inherit" />
</Backdrop>

  <div
      className='card-header border-0 cursor-pointer'
      role='button'
      data-bs-toggle='collapse'
      data-bs-target='#kt_account_profile_details'
      aria-expanded='true'
      aria-controls='kt_account_profile_details'
  >
      <div className='d-flex items-center '>
      <div className='mx-4 pb-1 mb-6'>
          <button type='button' value="Ar" onClick={GoToBack} className='btn btn-primary' >Back</button>

      </div>
      <div className='mx-4 pb-1 mb-6'>
          {selectedRadio ?
          <button type='button' value="Ar" onClick={Reload} className='btn btn-primary' >Reload</button> : <button type='button' value="Ar" onClick={Reload} className='btn btn-primary'disabled >Reload</button>
      }
      </div>
      </div>
      <div className='d-flex justify-content-between'>
          <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{AnalystName} Productivity Reports</h3>
          </div>
          <div>
              {/* {showing && <p>{`Showing all results of:`}<h6>{AnalystName}</h6></p>} */}

          </div>
      </div>
  </div>


{/* Reports wise */}
  <div className='row'>

  </div><hr />

  <div className='row'>
      <h3 className='mb-6'>Filter Reports by</h3>
      <div className='radios mb-6 d-flex justify-content-around'>
          <div className="form-check form-check-inline">

              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  value="OVER ALL PRODUCTIVITY" onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                  OVER ALL PRODUCTIVITY
              </label>
          </div>
          <div className="form-check form-check-inline">

              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  value="Day Wise" onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Day Wise
              </label>
          </div>
          <div className="form-check form-check-inline ">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  value="PAYER WISE" onChange={handleRadioChange}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                  PAYER WISE
              </label>
          </div>

          <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="STATUS WISE" onChange={handleRadioChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                  STATUS WISE
              </label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="PROVIDER WISE" onChange={handleRadioChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                  PROVIDER WISE
              </label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="PRACTICE WISE" onChange={handleRadioChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                  PRACTICE WISE
              </label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="ROOTCAUSE WISE" onChange={handleRadioChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                  ROOTCAUSE WISE
              </label>
          </div>
          <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                  value="AGING WISE" onChange={handleRadioChange} />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                  AGING WISE
              </label>
          </div>
      </div>
  </div>



  <div className=' mt-6 d-flex justify-content-center'>
      {PieChartData.length > 0 ?
          <div className='col-6 shadow '>
              <Chart chartType="PieChart" data={PieChartData} options={{ title: "Over All Performance", is3D: true, }} width={"100%"} height={"400px"} />
          </div> : null}
  </div>


  {/* Payer wise */}
  <div>
      <div className='d-flex  flex-row justify-content-around'>
      <div className='col-5 shadow'>
          {ChartDataForClaim.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartDataForClaim}
                  options={ChartOptionsForClaim}

                  />
              </div> : null}
              </div>
              <div className='col-5 shadow'>
          {ChartData.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartData} options={ChartOptions} />
              </div> : null}
              </div>
      </div>

      <div>

          <div className=' mt-8'>
              {PayerNameTableData?.length > 0 ? (
                       
             <TableRows data={PayerNameTableData} total={GrandTotal} title="PAYER WISE TABLE"/>
              ) :  (PayerNameTableData?.length === 0 &&  selectedRadio === "PAYER WISE") ?
                 <h4 className='text-center'>No Results Found</h4>
                                  : null
              }
          </div>
      </div>
      <div> 
              
                    {selectedRadio == "Day Wise"?

<table
id='kt_table_users'
className=' table-bordered align-middle fs-6 p-4  font-family-base no-footer w-full '
>
<thead>
  <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0 text-gray-800 py-6 px-6'>
    {   DayWiseUserData.inBetweenDates && DayWiseUserData.inBetweenDates.map((getData:any,ind:any)=>{
            return(
                <>
                 <th className='px-3 py-2' colSpan={2}>{getData}</th>
                </>
            )
        })
    }
    <th className='px-3 py-2'>Total Analysis</th>
    <th className='px-3 py-2'>Total PCA</th>
  </tr>
</thead>
<tbody>
    <tr>
    {    DayWiseUserData.inBetweenDates &&  DayWiseUserData.inBetweenDates.map((getData:any,ind:any)=>{
            return(
                <>
                    <td className="text-center p-2"><b>Analysis</b></td>
                    <td className="text-center p-2"><b>PCA</b></td>
                    
                </>
            )
        })
    }
      { DayWiseUserData.inBetweenDates ?
      <>
      <td className="text-center p-2" rowSpan={2}>{DayWiseUserData.TotalAnalysed}</td>
      <td className="text-center p-2" rowSpan={2}>{DayWiseUserData.TotalPCA}</td>
      </>
      : null
    }
    </tr>
    <tr>
    {    DayWiseUserData.inBetweenDates &&  DayWiseUserData.inBetweenDates.map((getData:any,ind:any)=>{
            return(
                <>
                    <td className="text-center p-2">{DayWiseUserData.data[0][getData]}</td>
                    <td className="text-center p-2">{DayWiseUserData.PCAWiseCount[0][getData]}</td>
                  
                </>
            )
        })
    }
   
    </tr>
    
</tbody>
</table> :null
}


                
      </div>
  </div>


  {/* Status wise */}

  <div>
      <div className='d-flex flex-row justify-content-around'>
      <div className='col-5 shadow'>
          {ChartStatusWiseDataForClaim.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartStatusWiseDataForClaim} options={ChartOptionsStatusForClaim} />
              </div> : null}
              </div>

              <div className='col-5 shadow'>
          {ChartStatusWiseData.length > 0 ?
              <div>
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartStatusWiseData} options={ChartOptionsStatus} />
              </div> : null}
              </div>
      </div>

      <div>

          <div className=' mt-8 '>

              {StatusTableData?.length > 0 ? (
                   <TableRows data={StatusTableData} total={GrandTotal} title="STATUS WISE TABLE"/>
              ) :
              (StatusTableData?.length === 0 && selectedRadio === "STATUS WISE")?
                 <h4 className='text-center'>No Results Found</h4>
                
              : null
             }
          </div>
      </div>
  </div>

  {/* Provider wise */}


  <div>
      <div className='d-flex flex-row justify-content-around'>
      <div className='col-5 shadow'>
          {ChartProviderDataForClaim.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartProviderDataForClaim} options={ChartOptionsProviderForClaim} />
              </div> : null}
              </div>
              <div className='col-5 shadow'>
          {ChartProviderData.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartProviderData} options={ChartOptionsProvider} />
              </div> : null}
              </div>
      </div>

      <div>

          <div className=' mt-8'>
              {ProviderTableData?.length > 0 ? (
                 <TableRows data={ProviderTableData} total={GrandTotal} title="PROVIDER WISE TABLE"/>
              ) :
              (ProviderTableData?.length === 0 && selectedRadio === "PROVIDER WISE") ?
                 <h4 className='text-center'>No Results Found</h4>
              
             : null

                 }
          </div>
      </div>
  </div>

  {/* Rootcause wise */}

  <div>
      <div className='d-flex flex-row justify-content-around'>
      <div className='col-5 shadow'>
          {RootCauseDataWiseDataForClaim.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={RootCauseDataWiseDataForClaim} options={ChartOptionsRootCauseForClaim} />
              </div> : null}
              </div>

              <div className='col-5 shadow'>
          {RootCauseDataWiseData.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={RootCauseDataWiseData} options={ChartOptionsRootCause} />
              </div> : null}
              </div>
      </div>

      <div>

          <div className=' mt-8'>
              {RootCauseTableData?.length > 0 ? (
              <TableRows data={RootCauseTableData} total={GrandTotal} title="ROOTCAUSE TABLE"/>
              ) :
              (RootCauseTableData?.length === 0 && selectedRadio === "ROOTCAUSE WISE")?
                 <h4 className='text-center'>No Results Found</h4>
              
                 : null}
          </div>
      </div>
  </div>


  {/* Practice wise */}
  <div>
      <div className='d-flex flex-row justify-content-around'>
      <div className='col-5 shadow'>
          {ChartPracticeWiseDataForClaim.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartPracticeWiseDataForClaim} options={ChartOptionsPracticeForClaim} />
              </div> : null}

              </div>

              <div className='col-5 shadow'>
          {ChartPracticeWiseData.length > 0 ?
              <div >
                  <Chart chartType="ColumnChart" width="100%" height="600px" data={ChartPracticeWiseData} options={ChartOptionsPractice} />
              </div> : null}
              </div>
      </div>

      <div>

          <div className=' mt-8'>
              {PracticeTableData.length > 0 ? (
               <TableRows data={PracticeTableData} total={GrandTotal} title="PRACTICE WISE TABLE"/>
              ) :
              (PracticeTableData?.length === 0 && selectedRadio === "PRACTICE WISE") ?
                 <h4 className='text-center'>No Results Found </h4>
               
                : null}
          </div>
      </div>
  </div>

  {/* Aging wise */}

  <div>
      <div className='d-flex flex-row justify-content-around mt--10'>
      <div className='col-5 shadow'>
          {ChartAgingWiseDataForClaim.length > 0 ? (
              <div >
                  <Chart
                      chartType='ColumnChart'
                      width='100%'
                      height='600px'
                      data={ChartAgingWiseDataForClaim}
                      options={ChartOptionsAgingForClaim}
                  />
              </div>
          ) : null}
</div>
<div className='col-5 shadow'>
          {ChartAgingWiseData.length > 0 ? (
              <div >
                  <Chart
                      chartType='ColumnChart'
                      width='100%'
                      height='600px'
                      data={ChartAgingWiseData}
                      options={ChartOptionsAging}
                  />
              </div>
          ) : null}
</div>

      </div>
      <div>
          <div className='d-flex justify-content-center mt-8'>
              {AgingeTableData?.length > 0 ? (
                  <AgingTable data={AgingeTableData} total={AgingTotal} title="AGING WISE TABLE"/>
              ) :(AgingeTableData?.length === 0 && selectedRadio === "AGING WISE") ?
                 <h4 className='text-center'>No Results Found </h4>
               : null}
          </div>

      </div>
  </div>
</div>

)
}
export { AnalystProductivity }
