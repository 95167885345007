import React, { useState, useEffect } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import Select from 'react-select';
import swal from "sweetalert"
import moment from "moment";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import NotUsedUserLogin,{ChildProps} from './Common/NotUsedUserLogin';
import NotUsedManagerLogin from './Common/NotUsedManagerLogin';
import PracticeWiseProduction from "./Common/PracticeWiseProduction"
import ConsolidatedProduction from "./Common/ConsolidatedProduction"
import ArAnalystProduction from "./Common/ArAnalystProduction"
import PracticePerformance from "./Common/PracticePerformance"
import "../wizards/common/style.css";
const getUserList = `/api/ConsolidatedProductivity/getUsersList`
const getPracticeList = `/api/practice/getPracticeListById`
const getPmList = `/api/pmsystem/getPmList`
const  GenerateNotUsedUserLogin = `api/Report/GenerateNotUsedUserLogin`
const GenerateExcelCallerWiseProduction = "api/ConsolidatedProductivity/GenerateExcelCallerWiseProduction"
const GenerateExcelAnalystWiseProduction = "api/AnalystProductivity/GenerateExcelAnalystWiseProduction"
const GenerateExcelPractieWiseProduction = "api/Report/GenerateExcelPractieWiseProduction"
const PracticePerformanceSummary = "api/PracticePerformance/PracticePerformanceSummary"
const Reports: React.FC = () => {

    const [ReportsListData, setReportsListData] = useState({label :"",value:""})
    const [UserNameDatas, setUserNameDatas] = useState([{ FirstName: "", _id: "" }])
    const [SelectedPmSystem, setSelectedPmSystem] = useState({label :"",value:""})
    const [childData, setChildData] = useState<ChildProps>();
    const [LabelName,setLabelName] = useState("")
    const [SelectedPractice, setSelectedPractice] = useState([])
    const [PracticeListData, setPracticeListData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [getPmListData, setdatas] = useState([{ PmName: "", _id: "" }])
    const [togglePlusValue, setTogglePlusValue] = useState(false)
    const [toggleMinusValue, setToggleMinusValue] = useState(true)
    const [SelectedManager, setSelectedManager] = useState()
    const [formData, setFormData] = useState({
        FromDate: '',
        ToDate: '',
    });
    const [FromDate, setFromDate] = useState("")
    const [DateMonthFrom, setDateMonthFrom] = useState("")
    const getReportsList = async (event: any) => {

        if (Object.keys(event).length > 0) {
            if(event.label === "AR-Caller productivity"){
                const Result = await axios.get(`${getUserList}`)
                setUserNameDatas(Result.data.Data)
                setLabelName("Caller List")
            }
            if(event.label === "AR Analyst Productivity"){
                setUserNameDatas([])
                const Result = await axios.get(`${getUserList}?Type=AR-Analyst`)
                setUserNameDatas(Result.data.Data)
                setLabelName("Analyst List")
            }
            setReportsListData(event)
        } else {
            setReportsListData({label :"",value:""})
        }
    }
    const getTeamMembers = async (event: any) => {
        setSelectedManager(event)
    };
    const GetPmList = async () => {
        const getPmListData = await axios.get(getPmList)
        if (getPmListData.data) {

            setdatas(getPmListData.data.data)
        }
    }

    useEffect(() => {
        GetPmList()
    }, [])

    const getPractice = async (event: any) => {

        sessionStorage.setItem("PracticeNameVC", JSON.stringify(event))

        if (Object.keys(event).length > 0) {
            setSelectedPractice(event)
        } else {
            setSelectedPractice([])
        }

    }
    const getPMSystem = (event: any) => {

        setSelectedPmSystem(event)
        setSelectedPractice([])
        sessionStorage.setItem("PmSystemVC", JSON.stringify(event))
        sessionStorage.removeItem("PracticeNameVC")

        if (event.value) {
            axios.post(getPracticeList, { PostData: event.value }).then((res) => {
                setPracticeListData(res.data.data)
            })
        }

    }
    var PmListData = [{ label: "Select", value: "" }]
    getPmListData.map((name) => (
        PmListData.push({
            label: name.PmName,
            value: name._id
        })
    ))
    const handleDateChangeFrom = (date: any) => {
        setDateMonthFrom(date)
        setFromDate(moment(date['$d']).format("MMM-YYYY"))
    };
    const handleInputChange = async (event: any) => {
      const {name, value} = event.target
      setFormData((prevState) => {
        return {...prevState, [name]: value}
      })
    }
    const SendMail =  async(event: any) => {
        swal({
            title: `${ReportsListData.label}`,
            content: {
                element: "div",
                attributes: {
                    innerHTML:
                        '<textarea id="field1" class="swal-content__input" placeholder="To Mail"></textarea><br/>' +
                        '<textarea value="rathi@scioms.com" id="field2" class="swal-content__input" placeholder="CC Mail" >rathi@scioms.com,</textarea>'
                }
            },
            buttons: {
                confirm: true,
            },
        }).then(async(value:any) => {
            if (value) {
                const field1Value = (document.getElementById('field1') as HTMLInputElement).value;
                const field2Value = (document.getElementById('field2') as HTMLInputElement).value;
                if(!ReportsListData.label){
                    return false;
                }
                if(!field1Value){
                    return false;
                }
                if(!field2Value){
                    return false;
                }
                var Url = "";
                if(ReportsListData.label == "Not Used User Logins" || ReportsListData.label == "Not Used Manager Logins"){
                    Url = `${GenerateNotUsedUserLogin}?ToMail=${field1Value}&CcMail=${field2Value}&type=Mail&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`
                }
                if(ReportsListData.label == "AR-Caller productivity"){
                    Url = `${GenerateExcelCallerWiseProduction}?ToMail=${field1Value}&CcMail=${field2Value}&type=Mail&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`
                }
                if(ReportsListData.label == "AR Analyst Productivity"){
                    Url = `${GenerateExcelAnalystWiseProduction}?ToMail=${field1Value}&CcMail=${field2Value}&type=Mail&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`
                }
                if(ReportsListData.label == "Practice wise production"){
                    Url = `${GenerateExcelPractieWiseProduction}?ToMail=${field1Value}&CcMail=${field2Value}&type=Mail&StartDate=${formData.FromDate}&ToDate=${formData.ToDate}`
                }
                if(ReportsListData.label == "Practice Performance"){
                    Url = `${PracticePerformanceSummary}?ToMail=${field1Value}&CcMail=${field2Value}&type=Mail&StartDate=${FromDate}&ToDate=${FromDate}`
                }
                setLoading(true)
                await axios.get(Url).then(_res => {
                    setLoading(false)
                    swal({
                        title: 'Success',
                        icon: "success",
                        text: `Mail Trigger Successfully...!`,
                    }).then((_res: any) => {
    
                    })
                }).catch((e)=>{
                    setLoading(false)
                    swal({
                        title: '',
                        icon: "info",
                        text: `Please wait some time you will get mail`,
                    }).then((_res: any) => {
    
                    })
                })
            }
        });

    }
    const handleSubmitForm = async(event: any) => {
        
        if(ReportsListData.label == "Practice wise production" || ReportsListData.label == "Practice Performance"){
            if(Object.keys(SelectedPmSystem).length > 0){
                if(SelectedPmSystem.value){
                    if(SelectedPractice.length == 0){
                        swal({
                            title: '',
                            icon: "info",
                            text: `Please select Practice`,
                        }).then((_res: any) => {
        
                        })
                        return false;
                    }
                }
                
            }
        }
        if(ReportsListData.label != "Practice Performance"){
            if(!formData.FromDate){
                swal({
                    title: '',
                    icon: "info",
                    text: `Please select From Date`,
                }).then((_res: any) => {
    
                })
                return false;
            }
            if(!formData.ToDate){
                swal({
                    title: '',
                    icon: "info",
                    text: `Please select To Date`,
                }).then((_res: any) => {
    
                })
                return false;
            }
        }
        setChildData({
            SelectedPmSystem : SelectedPmSystem,
            SelectedPractice : SelectedPractice,
            FromDate : formData.FromDate,
            ToDate : formData.ToDate,
            SelectedManager : SelectedManager,
            isSubmit : true,
            SelectMonth : FromDate
        }) 
    }
    const ClearFrom = async(event: any) => {
        setSelectedPmSystem({label :"",value:""})
        setSelectedPractice([])
        setFormData({FromDate :"",ToDate:""})
    }
    const handleChangePlus = (event: any) => {
        setTogglePlusValue(false)
        setToggleMinusValue(true)
    };
    const handleChangeMinus = (event: any) => {
        setTogglePlusValue(true)
        setToggleMinusValue(false)
    };
    var ReportList: any = [
        { value: "Not Used User Logins", label: "Not Used User Logins" },
        { value: "Not Used Manager Logins", label: "Not Used Manager Logins" },
        { value: "AR-Caller Productivity", label: "AR-Caller productivity" },
        { value: "AR Analyst Productivity", label: "AR Analyst Productivity" },
        { value: "Practice wise production", label: "Practice wise production" },
        { value: "Practice Performance", label: "Practice Performance" },
    ]
    var UsersListData = [{ label: "Select", value: "" }]
    UserNameDatas.map((userList, index) => {
      UsersListData.push({
        label: userList.FirstName,
        value: userList._id
      })
    })
    return (
        <div >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="header header_section">
                <span className='section'>Reports</span>
            </div>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <span className={`fa fa-2x fa-plus-square float-end fw-bolder m-0 ${togglePlusValue ? 'plusshow' : 'plushide'}`} onClick={handleChangePlus}></span>
                    <span className={`fa fa-2x fa-minus-square float-end fw-bolder m-0 ${toggleMinusValue ? 'minusshow' : 'minushide'}`} onClick={handleChangeMinus}></span>
                </div>
            </div>
            <hr />

            <div id="kt_account_profile_details" className='row col-12 collapse show'>
                <div className='accordion' id='kt_accordion_1'>
                    <div className='accordion-item'>

                        <div
                            id='kt_accordion_1_body_1'
                            className='accordion-collapse collapse show'
                            aria-labelledby='kt_accordion_1_header_1'
                            data-bs-parent='#kt_accordion_1'
                        >
                            <div className='accordion-body'>
                                <div className='row'>
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Reports List</span>
                                        </label>

                                        <Select maxMenuHeight={180} value={ReportsListData} onChange={getReportsList} id="ReportsList" name={"ReportsList"} options={ReportList} />

                                    </div>

                                    { ReportsListData.label &&  ReportsListData.label == "AR-Caller productivity" ||  ReportsListData.label &&  ReportsListData.label == "AR Analyst Productivity" ? 
                                        <div className='col-sm-4 fv-row'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                <span className='required'>{LabelName}</span>
                                            </label><br />
                                            <Select isMulti maxMenuHeight={180} value={SelectedManager} onChange={getTeamMembers} id="Manager" name={"Manager"} options={UsersListData} />
                                        </div> : null
                                    }
                                    { ReportsListData.label && ReportsListData.label == "Practice wise production" || ReportsListData.label && ReportsListData.label == "Practice Performance"  ? 
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>PM System</span>
                                        </label><br />
                                        <Select maxMenuHeight={180} value={
                                            SelectedPmSystem
                                        } onChange={getPMSystem} id="PmSystem" name={"PmSystem"} options={PmListData} />
                                    </div> : null }
                                    { ReportsListData.label && ReportsListData.label == "Practice wise production" ? 
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Practice Name</span>
                                        </label>
                                        <Select maxMenuHeight={180} value={SelectedPractice} isMulti onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                                    </div>  : null }
                                    { ReportsListData.label && ReportsListData.label == "Practice Performance" ? 
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Practice Name</span>
                                        </label>
                                        <Select maxMenuHeight={180} value={SelectedPractice}  onChange={getPractice} id="Practice" name={"Practice"} options={PracticeListData} />
                                    </div>  : null }

                                    { ReportsListData.label && ReportsListData.label != "Practice Performance" ? 
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>From Date</span>
                                        </label>
                                        <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.FromDate} name="FromDate" id="FromDate" className='form-control' />
                                    </div> : null
                                    }
                                     { ReportsListData.label && ReportsListData.label != "Practice Performance" ? 
                                    <div className='col-sm-4 fv-row'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span>To Date</span>
                                        </label>
                                        <input max={new Date().toISOString().split('T')[0]} onChange={handleInputChange} type="date" value={formData.ToDate} name="ToDate" id="ToDate" className='form-control' />
                                    </div>: null}
                                    { ReportsListData.label && ReportsListData.label == "Practice Performance" ? 
                                    <div className='col-sm-3 fv-row'>
                                        <label className='col-lg-6 col-form-label fw-bold fs-6'>
                                            <span className='required'>Month</span>
                                        </label><br />
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={DateMonthFrom}
                                                minDate={moment("2015")}
                                                renderInput={(params:any) => <TextField {...params}  />}
                                                maxDate={moment()}
                                                views={['year', 'month']} disableFuture
                                                onChange={handleDateChangeFrom}
                                            />
                                        </LocalizationProvider>
                                    </div> : null }
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                        <button  type='button' onClick={SendMail} className='btn btn-primary m-1' >Send Mail</button>
                                        <button  type='button' onClick={handleSubmitForm} className='btn btn-primary m-1' >Submit</button>
                                        <button  type='button' onClick={ClearFrom} className='btn btn-primary  m-1' >Clear</button>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            { ReportsListData.label  && ReportsListData.label == "Not Used User Logins"  ? <NotUsedUserLogin UserData={childData} /> : null }
            { ReportsListData.label  && ReportsListData.label == "Not Used Manager Logins"  ? <NotUsedManagerLogin   UserData={childData} /> : null }

            { ReportsListData.label  && ReportsListData.label == "Practice wise production"  ? <PracticeWiseProduction   UserData={childData} /> : null }
            
            { ReportsListData.label  && ReportsListData.label == "AR-Caller productivity"  ? <ConsolidatedProduction   UserData={childData} /> : null }

            { ReportsListData.label &&  ReportsListData.label == "AR Analyst Productivity"  ? <ArAnalystProduction   UserData={childData} /> : null }
            
            { ReportsListData.label  && ReportsListData.label == "Practice Performance"  ? <PracticePerformance   UserData={childData} /> : null }
            
        </div>
    )

}
export { Reports }